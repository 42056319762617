import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Communitylist.scss";
import CommunityDetail from "../CommunityDetail/CommunityDetails";

const CommunityList = () => {
  const [communities, setCommunities] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null); // State to hold the selected community
  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const response = await fetch("https://serverapi.biomophone.com/api/communities");
        const data = await response.json();
        setCommunities(data);
      } catch (error) {
        console.error("Error fetching communities:", error);
      }
    };

    fetchCommunities();
  }, []);

  // const handleCommunityClick = async (id) => {
  //   // Fetch the community details by id
  //   const response = await fetch(`https://serverapi.biomophone.com/api/communities/${id}`);
  //   const communityData = await response.json();
  //   setSelectedCommunity(communityData); // Set the selected community data
  // };

  const handleAddChannelClick = () => {
    navigate("/community/create"); // Navigate to the create community page
  };
  const handleCommunityClick = (id) => {
    navigate(`/community/${id}`); // Navigate to the detail page
  };
  
  return (
    <div className="community-list-container">
      <div className="sidebar">
        <h1>Channels</h1>
        <button className="add-channel-btn" onClick={handleAddChannelClick}>
          +
        </button>
      </div>
      <div className="main-content">
        <div className="search-bar">
          <input type="text" placeholder="Search channels..." />
        </div>
        <ul className="community-list">
          {communities.map((community) => (
            <li
              key={community.id}
              className="community-item"
              onClick={() => handleCommunityClick(community.id)}
            >
              <div className="community-avatar">
                <img
                  src={community.avatarUrl || "/default-avatar.png"}
                  alt={community.name}
                />
              </div>
              <div className="community-details">
                <h2>{community.name}</h2>
                <p>{community.description || "No description available"}</p>
                <span>{community.date}</span>
              </div>
            </li>
          ))}
        </ul>

        {selectedCommunity && (
          <CommunityDetail community={selectedCommunity} /> // Render CommunityDetail with the selected community data
        )}
      </div>
    </div>
  );
};

export default CommunityList;
