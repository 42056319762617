import React, { createContext, useState, useEffect } from "react";

export const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(() => {
    const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
    const user = userData.user || {}; // Ensure `user` is at least an empty object

    return {
      username: user.username || "",  // Safe access with fallback
      email: user.email || "",
      name: user.name || "",
      profilePicture: user.profilePic || "",
      bio: user.bio || "",
      privacy: user.privacy || "PUBLIC",
      interests: user.interests || [],
      userId: user.id || "", // Add userId to the context
      token: userData.token || "", // Add token to the context
    };
  });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
    const updatedUser = { ...userData.user, ...userProfile };
    localStorage.setItem(
      "user",
      JSON.stringify({ ...userData, user: updatedUser })
    );

    // Log the list of all logged-in users
    let allLoggedUsers = JSON.parse(localStorage.getItem("allLoggedUsers")) || [];
    console.log("Current allLoggedUsers:", allLoggedUsers);

    // Check if the user is already in the logged-in users list
    const isUserAlreadyLogged = allLoggedUsers.some(
      (user) => user.userId === userProfile.userId
    );
    console.log("Is this user already logged?", isUserAlreadyLogged);

    if (!isUserAlreadyLogged && userProfile.userId) {
      // Add the current user to the global list
      allLoggedUsers.push(userProfile);
      localStorage.setItem("allLoggedUsers", JSON.stringify(allLoggedUsers));
      console.log("Updated allLoggedUsers:", allLoggedUsers); // Log the updated list
    }
  }, [userProfile]);

  const updateUserProfile = (key, value) => {
    setUserProfile((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <UserProfileContext.Provider value={{ userProfile, updateUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};
