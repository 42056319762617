// import React, { useEffect, useState } from "react";
// import "./profile.scss";

// const Profile = () => {
//   const userData = JSON.parse(localStorage.getItem("user"));
//   const { token, user } = userData;
//   const { id: userId, username, email, name } = user;  // Extracting the user details from localStorage

//   const [profile, setProfile] = useState({});
//   const [bio, setBio] = useState("");
//   const [privacy, setPrivacy] = useState("PUBLIC");
//   const [interests, setInterests] = useState([]);
//   const [profilePicture, setProfilePicture] = useState("");

//   // Fetch profile data on component mount
//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         const res = await fetch(`https://serverapi.biomophone.com/api/Profile/profile/${userId}`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         console.log(await res.text()); 
//         if (!res.ok) {
//           const errorData = await res.json();
//           throw new Error(errorData.message || 'Failed to fetch profile.');
//         }
//         const data = await res.json();
//         setProfile(data);
//       } catch (error) {
//         console.error(error);
//         alert(error.message);
//       }
//     };
//     fetchProfile();
//   }, [userId, token]);

//   // const handleUpdate = async () => {
//   //   try {
//   //     const res = await fetch(`https://serverapi.biomophone.com/api/Profile/profile/${userId}`, {
//   //       method: "PUT",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //         Authorization: `Bearer ${token}`,
//   //       },
//   //       body: JSON.stringify({ bio, privacySettings: privacy, interests, img: profilePicture }),
//   //     });
//   //     const data = await res.json();
//   //     if (res.ok) {
//   //       alert("Profile updated!");
//   //     } else {
//   //       alert(data.message || "Failed to update profile.");
//   //     }
//   //   } catch (error) {
//   //     console.error(error);
//   //     alert("An error occurred while updating your profile.");
//   //   }
//   // };
//   const handleUpdate = async () => {
//     try {
//       const res = await fetch(`https://serverapi.biomophone.com/api/Profile/profile/${userId}`, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({ bio, privacySettings: privacy, interests, img: profilePicture }),
//       });
//       const data = await res.json();
//       if (res.ok) {
//         alert("Profile updated!");
//       } else {
//         alert(data.message || "Failed to update profile.");
//       }
//     } catch (error) {
//       console.error(error);
//       alert("An error occurred while updating your profile.");
//     }
//   };
  
//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       // reader.onload = () => setProfilePicture(reader.result);
//       // reader.readAsDataURL(file);
//       reader.onload = () => {
//         const imageUrl = reader.result;
//         setProfilePicture(imageUrl);
//         // Save the image URL to localStorage so the navbar can access it
//         const updatedUserData = JSON.parse(localStorage.getItem("user"));
//         updatedUserData.user.profilePic = imageUrl; // Save the updated image URL
//         localStorage.setItem("user", JSON.stringify(updatedUserData)); // Update localStorage
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <div className="profile">
//       <h2>{profile.username || "Your Profile"}</h2>
//       <div className="profile-details">
//         <p><strong>Username:</strong> {username}</p>
//         <p><strong>Email:</strong> {email}</p>
//         <p><strong>Name:</strong> {name}</p>
//       </div>
//       <img src={profilePicture} alt="Profile" className="profile-picture" />
//       <input type="file" accept="image/*" onChange={handleFileUpload} />
//       <textarea
//         value={bio}
//         onChange={(e) => setBio(e.target.value)}
//         placeholder="Write something about yourself..."
//       />
//       <div className="interests">
//         <input
//           type="text"
//           placeholder="Add interests"
//           onKeyDown={(e) => {
//             if (e.key === "Enter") {
//               setInterests([...interests, e.target.value]);
//               e.target.value = "";
//             }
//           }}
//         />
//         <ul>
//           {interests.map((interest, index) => (
//             <li key={index}>
//               {interest} <button onClick={() => setInterests(interests.filter((_, i) => i !== index))}>x</button>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <select value={privacy} onChange={(e) => setPrivacy(e.target.value)}>
//         <option value="PUBLIC">Public</option>
//         <option value="FRIENDS_ONLY">Friends Only</option>
//         <option value="PRIVATE">Private</option>
//       </select>
//       <button onClick={handleUpdate}>Update Profile</button>
//     </div>
//   );
// };

// export default Profile;




// import React, { useContext, useState } from "react";
// import { UserProfileContext } from "../../context/UserProfileContext";
// import "./profile.scss";

// const Profile = () => {
//   const { userProfile, updateUserProfile } = useContext(UserProfileContext);
//   const { username, email, name, profilePicture, bio, privacy, interests } = userProfile;

//   const [newInterest, setNewInterest] = useState("");

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         const updatedPicture = reader.result;
//         updateUserProfile("profilePicture", updatedPicture);

//         // Save updated profile picture to localStorage
//         const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
//         userData.user.profilePic = updatedPicture;
//         localStorage.setItem("user", JSON.stringify(userData));
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleAddInterest = () => {
//     if (newInterest) {
//       const updatedInterests = [...interests, newInterest];
//       updateUserProfile("interests", updatedInterests);

//       // Save interests to localStorage
//       const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
//       userData.user.interests = updatedInterests;
//       localStorage.setItem("user", JSON.stringify(userData));

//       setNewInterest("");
//     }
//   };

//   const handleRemoveInterest = (index) => {
//     const updatedInterests = interests.filter((_, i) => i !== index);
//     updateUserProfile("interests", updatedInterests);

//     // Save updated interests to localStorage
//     const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
//     userData.user.interests = updatedInterests;
//     localStorage.setItem("user", JSON.stringify(userData));
//   };

//   const handleUpdate = () => {
//     // Save full profile to localStorage
//     const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
//     userData.user = { ...userData.user, ...userProfile };
//     localStorage.setItem("user", JSON.stringify(userData));

//     alert("Profile updated!");
//   };

//   return (
//     <div className="profile">
//       <h2>{username || "Your Profile"}</h2>
//       <div className="profile-details">
//         <p><strong>Username:</strong> {username}</p>
//         <p><strong>Email:</strong> {email}</p>
//         <p><strong>Name:</strong> {name}</p>
//       </div>
//       <div className="profile-picture-section">
//         <img
//           src={profilePicture || "https://via.placeholder.com/150"}
//           alt="Profile"
//           className="profile-picture"
//         />
//         <input type="file" accept="image/*" onChange={handleFileUpload} />
//       </div>
//       <textarea
//         value={bio}
//         onChange={(e) => updateUserProfile("bio", e.target.value)}
//         placeholder="Write something about yourself..."
//       />
//       <div className="interests">
//         <input
//           type="text"
//           placeholder="Add interests"
//           value={newInterest}
//           onChange={(e) => setNewInterest(e.target.value)}
//         />
//         <button onClick={handleAddInterest}>Add</button>
//         <ul>
//           {interests.map((interest, index) => (
//             <li key={index}>
//               {interest}{" "}
//               <button onClick={() => handleRemoveInterest(index)}>x</button>
//             </li>
//           ))}
//         </ul>
//       </div>
//       <select value={privacy} onChange={(e) => updateUserProfile("privacy", e.target.value)}>
//         <option value="PUBLIC">Public</option>
//         <option value="FRIENDS_ONLY">Friends Only</option>
//         <option value="PRIVATE">Private</option>
//       </select>
//       <button onClick={handleUpdate}>Update Profile</button>
//     </div>
//   );
// };

// export default Profile;



import React, { useContext, useState } from "react";
import { UserProfileContext } from "../../context/UserProfileContext";
import "./profile.scss";

const Profile = () => {
  const { userProfile, updateUserProfile } = useContext(UserProfileContext);
  const { username, email, name, profilePicture, bio, privacy, interests } = userProfile;

  const [newInterest, setNewInterest] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState({ username, email, name, bio });

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedPicture = reader.result;
        updateUserProfile("profilePicture", updatedPicture);

        // Save updated profile picture to localStorage
        const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
        userData.user.profilePic = updatedPicture;
        localStorage.setItem("user", JSON.stringify(userData));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddInterest = () => {
    if (newInterest) {
      const updatedInterests = [...interests, newInterest];
      updateUserProfile("interests", updatedInterests);

      // Save interests to localStorage
      const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
      userData.user.interests = updatedInterests;
      localStorage.setItem("user", JSON.stringify(userData));

      setNewInterest("");
    }
  };

  const handleRemoveInterest = (index) => {
    const updatedInterests = interests.filter((_, i) => i !== index);
    updateUserProfile("interests", updatedInterests);

    // Save updated interests to localStorage
    const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
    userData.user.interests = updatedInterests;
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveProfile = () => {
    Object.keys(editedProfile).forEach((key) => {
      updateUserProfile(key, editedProfile[key]);
    });

    // Save full profile to localStorage
    const userData = JSON.parse(localStorage.getItem("user")) || { user: {} };
    userData.user = { ...userData.user, ...editedProfile };
    localStorage.setItem("user", JSON.stringify(userData));

    setIsEditing(false);
    alert("Profile updated successfully!");
  };

  return (
    <div className="profile">
      <h2>{username || "Your Profile"}</h2>
      <div className="profile-picture-section">
        <img
          src={profilePicture || "https://via.placeholder.com/150"}
          alt="Profile"
          className="profile-picture"
        />
        <input type="file" accept="image/*" onChange={handleFileUpload} />
      </div>
      {isEditing ? (
        <>
          <div className="edit-fields">
            <label>
              Username:
              <input
                type="text"
                value={editedProfile.username}
                onChange={(e) => setEditedProfile({ ...editedProfile, username: e.target.value })}
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                value={editedProfile.email}
                onChange={(e) => setEditedProfile({ ...editedProfile, email: e.target.value })}
              />
            </label>
            <label>
              Name:
              <input
                type="text"
                value={editedProfile.name}
                onChange={(e) => setEditedProfile({ ...editedProfile, name: e.target.value })}
              />
            </label>
            <label>
              Bio:
              <textarea
                value={editedProfile.bio}
                onChange={(e) => setEditedProfile({ ...editedProfile, bio: e.target.value })}
              />
            </label>
          </div>
          <button onClick={handleSaveProfile}>Save Changes</button>
        </>
      ) : (
        <>
          <div className="profile-details">
            <p>
              <strong>Username:</strong> {username}
            </p>
            <p>
              <strong>Email:</strong> {email}
            </p>
            <p>
              <strong>Name:</strong> {name}
            </p>
            <p>
              <strong>Bio:</strong> {bio || "No bio added yet."}
            </p>
          </div>
          <button onClick={handleEditToggle}>Edit Profile</button>
        </>
      )}
      <div className="interests">
        <input
          type="text"
          placeholder="Add interests"
          value={newInterest}
          onChange={(e) => setNewInterest(e.target.value)}
        />
        <button onClick={handleAddInterest}>Add</button>
        <ul>
          {interests.map((interest, index) => (
            <li key={index}>
              {interest}{" "}
              <button onClick={() => handleRemoveInterest(index)}>x</button>
            </li>
          ))}
        </ul>
      </div>
      <select
        value={privacy}
        onChange={(e) => updateUserProfile("privacy", e.target.value)}
      >
        <option value="PUBLIC">Public</option>
        <option value="FRIENDS_ONLY">Friends Only</option>
        <option value="PRIVATE">Private</option>
      </select>
    </div>
  );
};

export default Profile;
