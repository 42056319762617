import React from "react";
import "./modal.scss";
import axios from "axios";
import athena from "../../api/athena";
import { use } from "react";
const Modal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    // Retrieve user information and token from localStorage
    const userData = JSON.parse(localStorage.getItem("user"));
    const userId = userData?.id;
    const token = userData?.token;

    const handleLogout = async (removeAll = false) => {
        try {
            // Get user data from localStorage
            const userData = JSON.parse(localStorage.getItem("user"));
            
            // If no user data exists, alert and return
            if (!userData) {
                alert("No user is logged in");
                return;
            }
    
            // Extract userId and token from localStorage
            const { token, user } = userData;
            const { id: userId } = user;
    
            // Define the body for the request
            let body;
            if (removeAll === true) {
                body = {
                    userId,
                    token,
                    removeAll, // Indicate that all sessions should be removed
                };
            } else {
                body = {
                    userId,
                    token, // Standard logout from a single device
                };
            }
        
            // Send the logout request
            const response = await athena.post(
                "https://serverapi.biomophone.com/api/auth/logoutPrisma/", // Ensure your API endpoint is correct
                body // Pass the body directly, no need for the additional object wrapping
            );
        
            // Handle the response
            alert(response.data.message);
        
            // Clear the user data from localStorage
            localStorage.removeItem("user");
        
            // Close the modal after successful logout
            onClose();
        } catch (error) {
            // Handle any errors
            alert(error.response?.data?.error || "An error occurred during logout");
        }
    };
    
        return (
            <div className="modal-overlay">
                <div className="modal">
                    <button className="close-button" onClick={onClose}>
                        &times;
                    </button>
                    <h2>Session Management</h2>
                    <p>
                        You are logged in as <strong>{userData?.name}</strong>.
                    </p>
                    <button onClick={() => handleLogout(false)}>Sign Out</button>
                    <button onClick={() => handleLogout(true)}>
                        Sign Out from All Devices
                    </button>
                </div>
            </div>
        );
    };

    export default Modal;
