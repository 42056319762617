import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FriendRequests = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    fetchFriendRequests();
  }, []);

  const fetchFriendRequests = async () => {
    try {
      const response = await axios.get(`/api/friends/requests`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRequests(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccept = async (requestId) => {
    try {
      await axios.post(`/api/friends/accept-request/${requestId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      fetchFriendRequests();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDecline = async (requestId) => {
    try {
      await axios.post(`/api/friends/decline-request/${requestId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      fetchFriendRequests();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Friend Requests</h2>
      {requests.map((request) => (
        <div key={request.id}>
          <p>{request.sender.username}</p>
          <button onClick={() => handleAccept(request.id)}>Accept</button>
          <button onClick={() => handleDecline(request.id)}>Decline</button>
        </div>
      ))}
    </div>
  );
};

export default FriendRequests;
