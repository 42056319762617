import { useContext, useEffect, useState } from "react";
import React from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { UserProfileContext } from "./context/UserProfileContext";  
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Navbar from "./components/navbar/Navbar";
import LeftBar from "./components/leftBar/LeftBar";
import RightBar from "./components/rightBar/RightBar";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import CreateBorts from "./components/Reels/CreateBorts";
import CreateCommunityForm from "./components/CommunityForm/CreateCommunityForm";
import CommunityList from "./components/CommunityList/CommunityList";
import CommunityDetail from "./components/CommunityDetail/CommunityDetails";
import EventCreationForm from "./components/EventCreationForm/EventCreationForm";
import UserList from "./components/user/UserList";
import PremiumPage from "./pages/Premium/PremiumPage";
import AdvancedAnalytics from "./components/premium/AdvancedAnalytics";
import TipButton from "./components/premium/TripButton";
import AdComponent from "./components/premium/AdComponent";
import Payment from "./components/premium/Payment";
import SubscriptionUI from "./components/premium/Subscription";
import FriendRequests from "./components/Request/FreindRequests";

const queryClient = new QueryClient();
const stripePromise = loadStripe("your-stripe-public-key"); // Replace with your Stripe public key

const App = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { darkMode } = useContext(DarkModeContext);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Layout = () => (
    <div className={`theme-${darkMode ? "dark" : "light"}`}>
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          minHeight: "100vh",
        }}
      >
        {!isMobile && <LeftBar />}
        <div style={{ flex: 6 }}>
          <Outlet />
          {/* <AdComponent /> */}
        </div>
        <div style={{ flex: 3 }}>
          <RightBar />
          {!isMobile && <TipButton creatorId="12345" amount={10} />}
        </div>
      </div>
    </div>
  );

  const ProtectedRoute = ({ children }) => {
    if (!userProfile?.username) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <QueryClientProvider client={queryClient}>
          <Elements stripe={stripePromise}>
            <Layout />
          </Elements>
        </QueryClientProvider>
      ),
      children: [
        {
          path: "/",
          element: (
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          ),
        },
        {
          path: "/profile",
          element: (
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          ),
        },
        { path: "/reel", element: <CreateBorts /> },
        { path: "/community/create", element: <CreateCommunityForm /> },
        { path: "/community", element: <CommunityList /> },
        { path: "/community/:id", element: <CommunityDetail /> },
        { path: "/events", element: <EventCreationForm /> },
        { path: "/userlist", element: <UserList /> },
        { path: "/premium", element: <PremiumPage /> },
        { path: "/analytics", element: <AdvancedAnalytics /> },
        { path: "/subscription", element: <SubscriptionUI /> },
        {
          path: "/payment",
          element: (
            <Elements stripe={stripePromise}>
              <Payment />
            </Elements>
          ),
        },
        {path:"/friends", element:<FriendRequests/>}
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
  ]);

  return <RouterProvider router={router} />;
};

export default App;

