import Stories from "../../components/stories/Stories"
import Posts from "../../components/posts/Posts"
import React from "react";
import Share from "../../components/share/Share"
import "./home.scss"
import athena from "../../api/athena"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import StoryView from "../../components/stories/StoryView"
const handleLogout = async (navigate) => {
  try {
    const userData = JSON.parse(localStorage.getItem("user"));

    if (!userData) {
      alert("No user is logged in");
      navigate("/login"); // Redirect to login if no user
      return;
    }

    const { token, user } = userData;
    const { id: userId } = user;

    // Check if the user is logged in and if the token is valid
    const response = await athena.post("http://localhost:8800/api/auth/CheckIfUserLoggedInPrisma/", {
      userId,
      token
    });

    // If the user is not logged in or not found, remove the user from localStorage
    if (response.data.isLoggedIn === false) {
      alert(response.data.error); // Display the error message
      localStorage.removeItem("user"); // Remove the user from localStorage
      navigate("/login"); // Redirect to login page
    }

    // If the user is logged in, do nothing
  } catch (error) {
    alert(error.response?.data?.error || "An error occurred during logout");
  }
};

const Home = () => {
  const [userLoaded, setUserLoaded] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setUserLoaded(true); // Set user as loaded once the data is available
    }
  }, [user]);

  useEffect(() => {
    if (userLoaded) {
      handleLogout(navigate);
    }
  }, [userLoaded, navigate]);

  return (
    <div className="home">
      {/* <Stories/> */}
      <StoryView/>
      <Share/>
      <Posts/>
    </div>
  )
}

export default Home
