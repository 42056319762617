import axios from 'axios';
 // Replace with the actual environment variable

// Define your environment-based URL
const urls = {
  production: "https:localhost:8800/api", // Replace with actual production URL
  development: "https:localhost:8800/api", // Replace with actual dev URL if different
};

// Create a custom Axios instance (athena) with baseURL
const athena = axios.create({
  baseURL: urls.development, // Set the URL based on your environment (dev or prod)
  headers: {
    // Initially, no Authorization header, it will be set dynamically in the interceptor
  },
});

// Interceptor for adding the Authorization header before the request is made
athena.interceptors.request.use(
  async (config) => {
    // Get the user item from localStorage
    const userData = JSON.parse(localStorage.getItem('user'));

    // If user data exists, retrieve token and userId
    if (userData && userData.token) {
      const token = userData.token;
      config.headers.Authorization = `Bearer ${token}`; // Attach Bearer token
    }

    return config; // Return the updated config with the Authorization header
  },
  (err) => {
    return Promise.reject(err); // Reject the error if something goes wrong
  }
);

// Interceptor for handling the response
athena.interceptors.response.use(
  (response) => {
    return response; // If everything is fine, return the response
  },
  (err) => {
    // Handle errors such as unauthorized access (e.g., token expired)
    return Promise.reject(err); // Reject the error if not handled
  }
);

export default athena; // Export the custom Axios instance for use in the app
